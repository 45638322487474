import axios from 'axios'
import store from '@/store'
import router from '@/router'
import Vue from 'vue'
import i18n from '@/lang'

const baseUrl = process.env.VUE_APP_BEE_URL
const service = axios.create({
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
  timeout: 30000, // request timeout
})

service.interceptors.request.use(
  (config) => {
    if (
      config.url !== '/auth/newTokenWithGoogle' &&
      config.url !== '/user/registerByGoogle'
    ) {
      if (
        config.url === '/auth/newTokenWithPassword' ||
        config.url === '/auth/generateVeCodeImage'
      ) {
        config.headers['WCOM-UID'] = config.data.username.trim()
      } else {
        config.headers['WCOM-UID'] = store.getters.uid
      }
    }

    if (
      config.url !== '/auth/newTokenWithPassword' &&
      config.url !== '/auth/newTokenWithGoogle' &&
      config.url !== '/auth/renewToken' &&
      config.url !== '/user/registerByGoogle'
    ) {
      const token = store.getters.token
      if (token) {
        config.headers['WCOM-TOKEN'] = token
      }
    }
    if (
      config.url == '/broadcast/uploadFile' ||
      config.url == '/im/uploadFile'
    ) {
      console.log('---修改Content-type')
      config.headers['Content-type'] = 'multipart/form-data'
      console.log(config.data.get('upload'))
      if (!config.data) {
        // TODO:后端必须传data，即使数据是空
        config.data = {}
      }
    } else if (config.method.toUpperCase() === 'POST') {
      config.headers['Content-type'] = 'application/json'
      if (!config.data) {
        // TODO:后端必须传data，即使数据是空
        config.data = {}
      }
    }
    console.log('service-request', config)

    return config
  },
  (error) => {
    console.error(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    // console.log(response)
    const res = response.data
    if (res.mockData) {
      return res.mockData // mock处理
    }
    const errno = response.headers['wcom-errno']
    console.log(errno)
    if (errno === '3') {
      router.push(`/`)
    }
    console.log(res)
    if ('code' in res && res.msg == 'WCOM-TOKEN or WCOM-UID can not be empty') {
      let that = new Vue()
      that.$message({
        message: i18n.t('prompt.loginExpired'),
        type: 'error',
      })
      router.push(`/`)
    }
    return res
  },
  (error) => {
    console.error(error)
    console.log(error)
    // router.push(`/`)
    return Promise.reject(error)
  }
)

export default service
