export default {
  prompt: {
    loginOut: '退出个人云盘平台',
    isLoginOut: '确认退出登录平台',
    getSelfReportsListFail: '列表获取失败',
    packageDownloadMreportsFail: '列表文件包下载失败',
    exportsMreportMsgsFail: '列表导出文件失败',
    packageDownloadMreportItemFail: '上报文件下载失败',
    locationNot: '未上传定位',
    locationFail: '定位异常',
    locationanalysisFail: '位置解析失败',
    recordDeletionFail: '删除上报记录失败',
    loginExpired: '登录过期，重新登录',
    aDatePickerPlaceholder: '请选择日期',
  },
  title: {
    platformName: '个人云盘-管理台',
    WelcomeMessage: '欢迎您',
    uploaded: '上传时间',
    time_on: '开始时间',
    end_Time: '结束时间',
    fileName: '文件名',
    fileSize: '文件大小',
    uploadContent: '上传内容',
    fileLevel: '文件等级',
    generationTime: '生成时间',
    expirationTime: '过期时间',
    IMEINumber: '设备IMEI号',
    usernameAndUid: '用户名/账号',
    operate: '操作',
    view: '查看',
    ordinaryFiles: '普通文件',
    importantDocuments: '重要文件',
    detail: '查看详情',
    uploadPersonnel: '上传人员',
    recordDeletion: '记录删除',
    isRecordDeletion: '是否确认删除该记录',
    reportingRecords: '上报记录',
    locationAnalysis: '位置解析中',
    below: '降序',
    upper: '升序',
  },
  button: {
    cancel: '取 消',
    define: '确 定',
    searchFor: '搜索',
    resetting: '重置',
    img: '图片',
    video: '视频',
    audio: '音频',
    download: '下载',
    export: '导出',
    delete: '删除',
    refresh: '刷新',
  },
  login: {
    username: '账号',
    password: '密码',
    verificationCode: '验证码',
    rememberPassword: '记住密码',
    loginBtn: '登录',
    errmsg: {
      m0: '设备网络异常或服务器异常，请检查后重试',
      m1: '账号在其他设备登录',
      m2: '账号密码已更改需要重新登录',
      m3: '账号被注销',
      m4: '账号被禁用',
      m5: '账号禁止登录',
      m6: '验证码为空',
      m7: '该企业百度地图Akey异常！',
      m8: '验证码错误！',
      m9: '验证码失效！',
      m10: '普通用户无权登录调度台！',
      m11: '账号或密码错误，请检查后重试！',
    },
  },
  language: {
    simplifiedChinese: '简体',
    chineseTraditional: '繁体',
    english: '英文',
  },
}
