<template>
  <div class="zlp-view-img-wrap">
    <el-dialog
      :visible.sync="visible2"
      append-to-body
      :custom-class="'zlp-view-img-wrap'"
      @close="$emit('update:visible', false)"
    >
      <div class="cont">
        <img class="image" :src="seeImgData" alt="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    seeImgData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible2: this.visible,
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.zlp-view-img-wrap {
}
</style>
<style lang="scss">
.zlp-view-img-wrap {
  background-color: transparent;
  .el-dialog__body {
    padding: 0;
  }
  .cont {
    height: 666px;
    width: 100%;
    background-color: #fff;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
