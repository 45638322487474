import 'ant-design-vue/dist/antd.css'
import '@/styles/antd.scss' // global css

import { DatePicker, message } from 'ant-design-vue'
const { RangePicker } = DatePicker
export default {
  install(app) {
    [
      DatePicker,
      RangePicker
    ].forEach(c => {
      console.log('ant-design-vue', c);

      app.component(c.displayName || c.name, c)
    })
    //使用message需要配置这一步
    // app.prototype.$message = message;
  }
}