import XLSX from 'xlsx'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import Vue from 'vue'
import i18n from '@/lang'
// import { getFileBlobByFid } from "@/api/user.js";
import { getBidFile } from '@/api/loadrq.js' //适用于大文件设置的断开连接时间长

export function exportsMreportMsgs(data) {
  return new Promise((resolve, reject) => {
    try {
      console.log(i18n.t('title.platformName'))
      let tableAry = []
      tableAry.push([
        i18n.t('title.uploadContent'),
        i18n.t('title.fileLevel'),
        i18n.t('title.fileSize'),
        i18n.t('title.generationTime'),
        i18n.t('title.expirationTime'),
        i18n.t('title.IMEINumber'),
        i18n.t('title.usernameAndUid'),
        i18n.t('title.uploaded'),
      ])
      data.forEach((item) => {
        let row = [
          item.content,
          item.level == 0
            ? i18n.t('title.ordinaryFiles')
            : i18n.t('title.importantDocuments'),
          changeByte(item.file_size),
          dateFormat(item.gen_time),
          item.expire_time == 0 ? 0 : dateFormat(item.expire_time),
          item.device_id,
          item.user_id,
          dateFormat(item.report_time),
        ]
        tableAry.push(row)
      })
      // 创建sheet
      let ws = XLSX.utils.aoa_to_sheet(tableAry)
      // 创建workbook
      let wb = XLSX.utils.book_new()
      // 把sheet放入workbook
      XLSX.utils.book_append_sheet(wb, ws, i18n.t('title.reportingRecords'))
      // 写入文件(通过文件名控制导出的类型)
      XLSX.writeFile(
        wb,
        `${i18n.t('title.reportingRecords')}(${Math.ceil(
          new Date().getTime() / 1000
        )}).xlsx`
      )
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export function packageDownloadMreports(data) {
  return new Promise((resolve, reject) => {
    let filePromises = []
    const zip = new JSZip()
    let zipName = `${i18n.t('title.platformName')}${i18n.t(
      'title.reportingRecords'
    )}${Math.ceil(new Date().getTime() / 1000)}`
    let fileFolder = zip.folder(zipName)
    data.forEach((item) => {
      filePromises.push(getBidFile(item.file_id))
    })
    Promise.all(filePromises)
      .then((res) => {
        try {
          res.forEach((item, index) => {
            // const blob = new Blob([item]);
            // zip.file('fileName', blob, { binary: true })
            // `11(${Math.ceil(new Date().getTime() / 1000)}).` + item.type.slice(item.type.lastIndexOf("/") + 1)
            fileFolder.file(data[index].file_name, item, { binary: true })
            if (index + 1 == data.length) {
              zip.generateAsync({ type: 'blob' }).then(function (content) {
                FileSaver.saveAs(content, zipName + '.zip')
                resolve()
              })
            }
          })
        } catch (error) {
          reject(error)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function packageDownloadMreportItem(item) {
  return new Promise((resolve, reject) => {
    getBidFile(item.file_id)
      .then((res) => {
        console.log(res)
        // FileSaver.saveAs(res, `11(${Math.ceil(new Date().getTime() / 1000)}).` + res.type.slice(res.type.lastIndexOf("/") + 1))
        FileSaver.saveAs(res, item.file_name)
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const changeByte = (byte) => {
  let size = ''
  if (byte < 0.1 * 1024) {
    // 小于0.1KB，则转化成B
    size = `${byte.toFixed(2)}B`
  } else if (byte < 0.1 * 1024 * 1024) {
    // 小于0.1MB，则转化成KB
    size = `${(byte / 1024).toFixed(2)}KB`
  } else if (byte < 0.1 * 1024 * 1024 * 1024) {
    // 小于0.1GB，则转化成MB
    size = `${(byte / (1024 * 1024)).toFixed(2)}MB`
  } else {
    // 其他转化成GB
    size = `${(byte / (1024 * 1024 * 1024)).toFixed(2)}GB`
  }

  const sizeStr = `${size}` // 转成字符串
  const index = sizeStr.indexOf('.') // 获取小数点处的索引
  const dou = sizeStr.substr(index + 1, 2) // 获取小数点后两位的值
  // eslint-disable-next-line eqeqeq
  if (dou == '00') {
    // 判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size
}
/**
 * 日期格式化
 */
export function dateFormat(timeStamp, format) {
  const date = new Date(parseInt(timeStamp) * 1000)
  format = format || 'yyyy-MM-dd hh:mm:ss'
  if (date !== 'Invalid Date') {
    const o = {
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // day
      'h+': date.getHours(), // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
      S: date.getMilliseconds(), // millisecond
    }
    if (/(y+)/.test(format)) {
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return format
  }
  return ''
}
