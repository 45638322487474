import {
  newTokenWithPassword,
  getSelfOrgName,
  revokeToken,
  getReportsList,
  getSelfReportsList,
  deleteReports,
} from '@/api/user'
import {
  getUserId,
  setUserId,
  getToken,
  getLanguage,
  setToken,
  setLanguage,
  removeToken,
  getUserDeatil,
  setUserDeatil,
} from '@/utils'
console.log(getUserDeatil())
const state = {
  uid: getUserId(),
  token: getToken(),
  refresh_token: '',
  userOrgDetails: getUserDeatil(),
  language: getLanguage(),
}

const getters = {}

const mutations = {
  SET_UID: (state, uid) => {
    state.uid = uid
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_REFRESH_TOKEN: (state, token) => {
    state.refresh_token = token
  },
  setUserOrgDetails: (state, details) => {
    for (const key in details) {
      state.userOrgDetails[key] = details[key]
    }
  },
  SET_Language(state, language) {
    state.language = language
  },
}

const actions = {
  setLanguage({ state, dispatch, commit, rootGetters }, language) {
    setLanguage(language)
    commit('SET_Language', language)
  },
  getBsType({ state, dispatch, commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      getBsType(rootGetters['user/getUserDetails'].rorg_id)
        .then((resp) => {
          console.log('getBsType', resp)
          if ('code' in resp) {
            reject(resp)
          } else {
            resolve(resp.items)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  login({ state, dispatch, commit, rootGetters }, userInfo) {
    return new Promise((resolve, reject) => {
      const { username, password, picCode, verificationCode } = userInfo
      newTokenWithPassword(username, password, picCode, verificationCode)
        .then((resp) => {
          console.log('newTokenWithPassword', resp)
          if ('code' in resp) {
            reject(resp)
          } else {
            commit('SET_UID', username.trim())
            commit('SET_TOKEN', resp.token)
            setUserId(username.trim())
            setToken(resp.token)
            commit('SET_REFRESH_TOKEN', resp.refresh_token)
            // 获取自身组织信息
            dispatch('getSelfOrgName')
              .then((resp2) => {
                if ('code' in resp2) {
                  reject(resp2)
                } else {
                  commit('setUserOrgDetails', resp2)
                  setUserDeatil(resp2)
                  resolve()
                }
              })
              .catch((error) => {
                reject(error)
              })
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSelfOrgName() {
    return new Promise((resolve, reject) => {
      getSelfOrgName()
        .then((resp) => {
          console.log('getSelfOrgName', resp)
          if ('code' in resp) {
            reject(resp)
          } else {
            resolve(resp)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  loginOut() {
    return new Promise((resolve, reject) => {
      removeToken()
      revokeToken()
        .then((resp) => {
          console.log('revokeToken', resp)
          if ('code' in resp) {
            reject(resp)
          } else {
            resolve(resp)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getReportsList({ state, dispatch, commit, rootGetters }, params) {
    return new Promise((resolve, reject) => {
      getReportsList(params)
        .then((resp) => {
          console.log('getReportsList', resp)
          if ('code' in resp) {
            reject(resp)
          } else {
            let newParams = params
            newParams.data.push(...resp.items)
            if (resp.items.length < params.size) {
              // 请求完了
              resolve(newParams.data)
            } else {
              newParams.cursor = resp.cursor
              dispatch('getReportsList', params)
                .then((res) => {
                  resolve(res)
                })
                .catch((err) => {
                  reject(err)
                })
            }
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSelfReportsList({ state, dispatch, commit, rootGetters }, params) {
    return new Promise((resolve, reject) => {
      getSelfReportsList(params)
        .then((resp) => {
          console.log('getSelfReportsList', resp)
          if ('code' in resp) {
            reject(resp)
          } else {
            let newParams = params
            newParams.data.push(...resp.items)
            if (resp.items.length < params.size) {
              // 请求完了
              resolve(newParams.data)
            } else {
              newParams.cursor = resp.cursor
              dispatch('getSelfReportsList', params)
                .then((res) => {
                  resolve(res)
                })
                .catch((err) => {
                  reject(err)
                })
            }
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteReports({ state, dispatch, commit, rootGetters }, report_ids) {
    return new Promise((resolve, reject) => {
      deleteReports(state.userOrgDetails.rorg_id, report_ids)
        .then((resp) => {
          console.log('deleteReports', resp, report_ids)
          if ('code' in resp) {
            reject(resp)
          } else {
            resolve()
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
