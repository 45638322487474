export default {
  prompt: {
    loginOut: 'Exit personal cloud disk platform',
    isLoginOut: 'Confirm logging out of the platform',
    getSelfReportsListFail: 'List acquisition failed',
    packageDownloadMreportsFail: 'List file package download failed',
    exportsMreportMsgsFail: 'List export file failed',
    packageDownloadMreportItemFail: 'Failed to download the reported file',
    locationNot: 'Location not uploaded',
    locationFail: 'Abnormal positioning',
    locationanalysisFail: 'Location resolution failed',
    recordDeletionFail: 'Failed to delete the report record',
    loginExpired: 'Login expired, log in again',
    aDatePickerPlaceholder: 'Please select a date',
  },
  title: {
    platformName: 'Personal storage space',
    WelcomeMessage: 'Welcome',
    uploaded: 'uploaded',
    time_on: 'time_on',
    end_Time: 'end_Time',
    fileName: 'file Name',
    fileSize: 'file Size',
    uploadContent: 'upload Content',
    fileLevel: 'file Level',
    generationTime: 'generation Time',
    expirationTime: 'expiration Time',
    IMEINumber: 'IMEI Number',
    usernameAndUid: 'usernameOrUid',
    operate: 'operate',
    view: 'view',
    ordinaryFiles: 'ordinary Files',
    importantDocuments: 'important Documents',
    detail: 'detail',
    uploadPersonnel: 'upload Personnel',
    recordDeletion: 'record Deletion',
    isRecordDeletion: 'Are you sure to delete this record',
    reportingRecords: 'reporting Records',
    locationAnalysis: 'location Analysis',
    below: 'below',
    upper: 'upper',
  },
  button: {
    cancel: 'cancel',
    define: 'define',
    searchFor: 'search',
    resetting: 'reset',
    img: 'img',
    video: 'video',
    audio: 'audio',
    download: 'download',
    export: 'export',
    delete: 'delete',
    refresh: 'refresh',
  },
  login: {
    username: 'account',
    password: 'password',
    verificationCode: ' Code',
    rememberPassword: 'remember password',
    loginBtn: 'Login',
    errmsg: {
      m0: 'The network of the device or server is abnormal. Check and try again',
      m1: 'The account is used to log in to another device',
      m2: 'The account password has been changed. You need to log in again',
      m3: 'Account is cancelled',
      m4: 'The account is disabled.',
      m5: 'Account login prohibition',
      m6: 'The verification code is empty',
      m7: 'The enterprise Baidu map Akey exception!',
      m8: 'Verification code error!',
      m9: 'Verification code is invalid!',
      m10: 'Ordinary users do not have the right to log in to the dispatcher!',
      m11: 'Account or password error, please check and try again!',
    },
  },
  language: {
    simplifiedChinese: 'Chinese',
    chineseTraditional: 'T-Chinese',
    english: 'English',
  },
}
