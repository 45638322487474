import Vue from 'vue'
import VueI18n from 'vue-i18n'

import eleLocale from 'element-ui/lib/locale' //兼容element
import Cookies from 'js-cookie'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang
import elementTWLocale from 'element-ui/lib/locale/lang/zh-TW' // element-ui lang
import enLocale from './en'
import zhLocale from './zh'
import twLocale from './tw'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
  },
  tw: {
    ...twLocale,
    ...elementTWLocale,
  },
}
export function getLanguage() {
  // return 'en'
  const chooseLanguage = Cookies.get('language')
  console.log(
    'getLanguage',
    navigator.language,
    navigator.language.toLowerCase()
  )
  if (chooseLanguage) return chooseLanguage
  Cookies.set('language', 'zh')
  return 'zh'
  // if has not choose language
  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages,
})
eleLocale.i18n((key, value) => i18n.t(key, value)) //兼容element
export default i18n
