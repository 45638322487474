import { getBD09Point, getPointAddr } from '@/api/location'
import Vue from 'vue'
import i18n from '@/lang'

const state = {
  akey: 'A5Wyagd6FhHnfcIkhSpA4LApIeKdQiLg',
}

const getters = {}

const mutations = {}

const actions = {
  getBD09Point({ state, commit, dispatch, rootGetters }, params) {
    return new Promise((resolve, reject) => {
      let { type, point } = params
      getBD09Point(type, point, state.akey)
        .then((newpoint) => {
          if (newpoint.status == 0) {
            // 转换坐标以及位置后返回
            let newPoint = {}
            newPoint.lng = newpoint.result[0].x
            newPoint.lat = newpoint.result[0].y
            resolve(newPoint)
          } else {
            reject(newpoint)
          }
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getLocationAddr({ state, commit, dispatch, rootGetters }, point) {
    // 获取坐标的信息
    return new Promise((resolve, reject) => {
      getPointAddr(point, state.akey)
        .then((addr) => {
          resolve(addr)
        })
        .catch((error) => {
          try {
            let obj = {}
            obj.status = 0
            obj.result = {}
            obj.result.formatted_address = i18n.t('prompt.locationanalysisFail')
            resolve(obj)
          } catch (error) {
            reject(error)
          }
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
