import axios from 'axios'
import store from '@/store'
import router from '@/router'

const baseUrl = process.env.VUE_APP_BEE_URL
// if (process.env.NODE_ENV === 'production') { // 生成环境
// baseUrl = config?.baseURL + baseUrl
// }
const service = axios.create({
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000, // request timeout
})

service.interceptors.request.use(
  (config) => {
    config.headers['WCOM-UID'] = store.getters.uid

    const token = store.getters.token
    if (token) {
      config.headers['WCOM-TOKEN'] = token
    }
    config.headers['Content-type'] = 'multipart/form-data'
    if (!config.data) {
      // TODO:后端必须传data，即使数据是空
      // console.log('进来了吗')
      config.data = {}
    }
    return config
  },
  (error) => {
    console.error(error)
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  (response) => {
    // console.log(response)
    const res = response.data
    if (res.mockData) {
      return res.mockData // mock处理
    }
    const errno = response.headers['wcom-errno']
    // console.log(errno)
    if (errno === '3') {
      router.push(`/`)
    }
    return res
  },
  (error) => {
    console.error(error)
    return Promise.reject(error)
  }
)

export function getBidFile(fid) {
  return service({
    url: '/file/download/' + fid,
    responseType: 'blob',
    method: 'get',
    data: {},
  })
}
