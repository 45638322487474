<template>
  <div class="zlp-dialog msg-map">
    <div class="map magmap" id="magmap"></div>
    <div class="addr">{{ msgAddr }}</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'msgDetails',
  components: {},
  props: {
    seeItemData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      zlpMap: null,
      alarmLookMaker: null,
      alarmLookLabel: null,
      msgAddr: this.$t('title.locationAnalysis'),
      loc: {},
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    let loc = JSON.parse(this.seeItemData.location)
    console.log(loc)
    if (loc.lng == 0 || loc.lat == 0) {
      this.msgAddr = this.$t('prompt.locationNot')
    } else {
      let point = [loc.lng, loc.lat]
      // 获取PD09
      this.$store
        .dispatch('location/getBD09Point', {
          type: 3,
          point: point.toString(),
        })
        .then((newpoint) => {
          this.loc = { lng: newpoint.lng, lat: newpoint.lat }
          this.initMap()
          this.getAddr()
        })
        .catch((err) => {
          console.log(err)
          this.msgAddr = this.$t('prompt.locationFail')
          this.loc = { lng: 0, lat: 0 }
        })
    }
  },
  beforeDestroy() {
    if (this.zlpMap) {
      console.log('initMap-销毁地图')
      this.zlpMap.removeEventListener('mousemove')
      this.zlpMap.removeEventListener('dragstart')
      this.zlpMap.removeEventListener('zoomstart')
      this.zlpMap.removeEventListener('dblclick')
      this.zlpMap.removeEventListener('click')
      this.zlpMap.removeEventListener('resize')
      // this.zlpMap.destroy();
      console.log('initMap-销毁地图-over')
    }
  },
  methods: {
    initMap() {
      try {
        this.zlpMap = new BMapGL.Map('magmap')
        let point = {}
        if (this.loc.lng !== 0 && this.loc.lat !== 0) {
          point = new BMapGL.Point(this.loc.lng, this.loc.lat)
        } else {
          point = new BMapGL.Point(116.39548, 39.932909)
        }
        this.zlpMap.centerAndZoom(point, 12)
        this.zlpMap.setDefaultCursor('pointer')
        this.zlpMap.enableScrollWheelZoom(true)
        let icon = new BMapGL.Icon(require(`@/assets/images/on-line.png`), {
          width: 26,
          height: 26,
        })
        //
        if (this.loc.lng !== 0 && this.loc.lat !== 0) {
          this.alarmLookMaker = new BMapGL.Marker(point, {
            icon,
            offset: { width: 0, height: -13 },
          })
          // 查看告警label
          this.alarmLookLabel = new BMapGL.Label('· 测试', {
            position: point,
            offset: new BMapGL.Size(-50, -60),
          })
          this.alarmLookLabel.setStyle({
            backgroundColor: '#44ba35',
            color: '#fff',
            width: '100px',
            textAlign: 'center',
            overflow: 'hidden',
            fontSize: '12px',
            borderRadius: '13px',
            borderColor: '#44ba35',
            padding: '2px 0px',
          })
          // 更新label
          this.alarmLookLabel.setContent(`· ${this.seeItemData.user_id}`)
          this.alarmLookLabel.setStyle({
            backgroundColor: true ? '#44ba35' : '#84939e',
            borderColor: true ? '#44ba35' : '#84939e',
          })
          this.alarmLookMaker.setIcon(icon)
          // this.$nextTick(() => {
          //   this.alarmLookMaker.setPosition(point);
          // });
          this.zlpMap.addOverlay(this.alarmLookMaker)
          this.zlpMap.addOverlay(this.alarmLookLabel)
          // this.alarmLookLabel.hide();
          this.alarmLookLabel.show()
          this.alarmLookMaker.show()
          // this.alarmLookMaker.hide();
        }
      } catch (error) {
        console.log(error)
      }
    },
    getAddr() {
      this.$store
        .dispatch(
          'location/getLocationAddr',
          [this.loc.lat, this.loc.lng].toString()
        )
        .then((address) => {
          // console.log('有返回');
          if (address.status == 0) {
            this.msgAddr = address.result.formatted_address
            // .slice(0, 10)
          } else {
            this.msgAddr = this.$t('prompt.locationanalysisFail')
          }
          console.log('getLocationAddr=report', this.msgAddr, address)
        })
        .catch((err) => {
          console.log(err)
          this.msgAddr = this.$t('prompt.locationanalysisFail')
        })
    },
  },
}
</script>
<style lang="scss" scoped>
// 文件详情
.msg-map {
  width: 100%;
  height: 100%;
}
.map {
  width: 100%;
  height: 100%;
}
.addr {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 36px;
  background-color: rgba(#000000, 0.3);
  line-height: 36px;
  padding-left: 6px;
}
</style>
