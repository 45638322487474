import Cookies from 'js-cookie'
export function getUserId() {
  return Cookies.get('WCOM-UID') || ''
}
export function setUserId(uid) {
  Cookies.set('WCOM-UID', uid)
}
export function getUserDeatil() {
  return Cookies.get('WCOM-USER-DETAIL')
    ? JSON.parse(Cookies.get('WCOM-USER-DETAIL'))
    : {}
}
export function setUserDeatil(detail) {
  Cookies.set('WCOM-USER-DETAIL', JSON.stringify(detail))
}
export function getToken() {
  return Cookies.get('WCOM-TOKEN') || ''
}
export function setToken(Token) {
  Cookies.set('WCOM-TOKEN', Token)
}
export function removeToken(Token) {
  Cookies.remove('WCOM-TOKEN')
}
export function getLanguage() {
  return Cookies.get('language')
}
export function setLanguage(Language) {
  Cookies.set('language', Language)
}
