export default {
  prompt: {
    loginOut: '退出個人雲盤平臺',
    isLoginOut: '確認退出登錄平臺',
    getSelfReportsListFail: '列錶獲取失敗',
    packageDownloadMreportsFail: '列錶文件包下載失敗',
    exportsMreportMsgsFail: '列錶導出文件失敗',
    packageDownloadMreportItemFail: '上報文件下載失敗',
    locationNot: '未上傳定位',
    locationFail: '定位異常',
    locationanalysisFail: '位置解析失敗',
    recordDeletionFail: '刪除上報記錄失敗',
    loginExpired: '登錄過期，重新登錄',
    aDatePickerPlaceholder: '请选择日期',
  },
  title: {
    platformName: '個人雲盤-管理臺',
    WelcomeMessage: '歡迎您',
    uploaded: '上傳時間',
    time_on: '開始時間',
    end_Time: '結束時間',
    fileName: '文件名',
    fileSize: '文件大小',
    uploadContent: '上傳內容',
    fileLevel: '文件等級',
    generationTime: '生成時間',
    expirationTime: '過期時間',
    IMEINumber: '設備IMEI號',
    usernameAndUid: '用戶名/賬號',
    operate: '操作',
    view: '查看',
    ordinaryFiles: '普通文件',
    importantDocuments: '重要文件',
    detail: '查看詳情',
    uploadPersonnel: '上傳人員',
    recordDeletion: '記錄刪除',
    isRecordDeletion: '是否確認刪除該記錄',
    reportingRecords: '上報記錄',
    locationAnalysis: '位置解析中',
    below: '降序',
    upper: '升序',
  },
  button: {
    cancel: '取 消',
    define: '確 定',
    searchFor: '搜索',
    resetting: '重置',
    img: '圖片',
    video: '視頻',
    audio: '音頻',
    download: '下載',
    export: '導出',
    delete: '刪除',
    refresh: '刷新',
  },
  login: {
    username: '賬號',
    password: '密碼',
    verificationCode: '驗證碼',
    rememberPassword: '記住密碼',
    loginBtn: '登錄',
    errmsg: {
      m0: '設備網絡異常或服務器異常，請檢查後重試',
      m1: '賬號在其他設備登錄',
      m2: '賬號密碼已更改需要重新登錄',
      m3: '賬號被註銷',
      m4: '賬號被禁用',
      m5: '賬號禁止登錄',
      m6: '驗證碼為空',
      m7: '該企業百度地圖Akey異常！',
      m8: '驗證碼錯誤！',
      m9: '驗證碼失效！',
      m10: '普通用戶無權登錄調度臺！',
      m11: '賬號或密碼錯誤，請檢查後重試！',
    },
  },
  language: {
    simplifiedChinese: '簡體',
    chineseTraditional: '繁體',
    english: '英文',
  },
}
