<template>
  <div class="content">
    <!-- 顶栏第一层筛选按钮 -->
    <div class="select-header">
      <div class="select-item">
        <div class="label">{{ $t('title.uploaded') }}</div>
        <div class="selectbox">
          <!--  <a-range-picker
            v-model:value="selectTime"
            :allowClear="false"
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm"
            :placeholder="[$t('title.time_on'), $t('title.end_Time')]"
          /> -->
          <a-date-picker
            :allowClear="false"
            v-model:value="selectData"
            :placeholder="$t('prompt.aDatePickerPlaceholder')"
            :locale="locale"
          />
        </div>
        <!-- :locale="locale" -->
      </div>
      <div class="btn">
        <el-button
          size="small "
          :disabled="searchDiabel"
          type="primary"
          @click="getMsgs"
          >{{ $t('button.searchFor') }}</el-button
        >
      </div>
      <div class="btn">
        <el-button
          size="small "
          :disabled="resettingDiabel"
          type="primary"
          @click="resetting"
          >{{ $t('button.refresh') }}</el-button
        >
      </div>
    </div>
    <!-- 顶栏第二行操作按钮 -->
    <div class="select-header-two">
      <div class="switch-list">
        <div
          :class="['item', listType == 0 ? 'item-selete' : '']"
          @click="setListType(0)"
        >
          {{ $t('button.img') }}
        </div>
        <div
          :class="['item', listType == 1 ? 'item-selete' : '']"
          @click="setListType(1)"
        >
          {{ $t('button.video') }}
        </div>
        <!-- 'item-center', -->
        <div
          v-if="false"
          :class="['item', listType == 2 ? 'item-selete' : '']"
          @click="setListType(2)"
        >
          {{ $t('button.audio') }}
        </div>
      </div>
      <div class="top-multibox">
        <div class="btn">
          <el-button
            type="primary"
            size="small "
            :disabled="selectItems.length == 0"
            @click="donwLoadMsgsFile"
            >{{ $t('button.download') }}</el-button
          >
        </div>
        <div class="btn">
          <el-button
            type="primary"
            size="small "
            :disabled="selectItems.length == 0"
            @click="exportsMsgs"
            >{{ $t('button.export') }}</el-button
          >
        </div>
        <div class="btn btn-danger" v-if="false">
          <el-button
            type="danger"
            :disabled="selectItems.length == 0"
            size="small "
            @click="deleteMsg(0)"
            >{{ $t('button.delete') }}</el-button
          >
        </div>
      </div>
    </div>

    <!-- 文件列表主体 -->
    <el-table
      ref="enterpriseMembersTable"
      :data="msgTableSortData"
      tooltip-effect="dark"
      style="width: 100%"
      height="600"
      :max-height="770"
      :border="true"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        header-align="center"
        :resizable="false"
        :align="'center'"
      >
      </el-table-column>
      <el-table-column
        prop="content"
        :label="$t('title.uploadContent')"
        header-align="center"
        :resizable="false"
        :align="'center'"
      >
        <!-- width="180" -->
      </el-table-column>
      <el-table-column
        :label="$t('title.fileSize')"
        header-align="center"
        :align="'center'"
        prop="file_size"
        :resizable="false"
      >
        <template slot="header" slot-scope="scope">
          <div class="t-header" @click="clickSort('fileSize')">
            <div class="h-lable">{{ $t('title.fileSize') }}</div>
            <div class="icon">
              <img
                :src="
                  require(`@/assets/images/${
                    sortItem.fileSize ? 'below' : 'upper'
                  }-${takeEffectSort == 'fileSize' ? 'open' : 'off'}.png`)
                "
                :title="
                  sortItem.fileSize ? $t('title.below') : $t('title.upper')
                "
              />
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          {{ getMsgFileSize(scope.row.file_size) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('title.generationTime')"
        header-align="center"
        :align="'center'"
        prop="gen_time"
        :resizable="false"
      >
        <template slot="header" slot-scope="scope">
          <div class="t-header" @click="clickSort('generationTime')">
            <div class="h-lable">{{ $t('title.generationTime') }}</div>
            <div class="icon">
              <img
                :src="
                  require(`@/assets/images/${
                    sortItem.generationTime ? 'below' : 'upper'
                  }-${takeEffectSort == 'generationTime' ? 'open' : 'off'}.png`)
                "
                :title="
                  sortItem.generationTime
                    ? $t('title.below')
                    : $t('title.upper')
                "
              />
            </div>
          </div>
        </template>

        <template slot-scope="scope">
          {{ getTimer(scope.row.gen_time) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('title.expirationTime')"
        header-align="center"
        :align="'center'"
        :resizable="false"
        prop="expire_time"
      >
        <template slot="header" slot-scope="scope">
          <div class="t-header" @click="clickSort('expirationTime')">
            <div class="h-lable">{{ $t('title.expirationTime') }}</div>
            <div class="icon">
              <img
                :src="
                  require(`@/assets/images/${
                    sortItem.expirationTime ? 'below' : 'upper'
                  }-${takeEffectSort == 'expirationTime' ? 'open' : 'off'}.png`)
                "
                :title="
                  sortItem.expirationTime
                    ? $t('title.below')
                    : $t('title.upper')
                "
              />
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          {{ getTimer(scope.row.expire_time) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('title.uploaded')"
        header-align="center"
        :align="'center'"
        :resizable="false"
        prop="report_time"
      >
        <template slot="header" slot-scope="scope">
          <div class="t-header" @click="clickSort('uploaded')">
            <div class="h-lable">{{ $t('title.uploaded') }}</div>
            <div class="icon">
              <img
                :src="
                  require(`@/assets/images/${
                    sortItem.uploaded ? 'below' : 'upper'
                  }-${takeEffectSort == 'uploaded' ? 'open' : 'off'}.png`)
                "
                :title="
                  sortItem.uploaded ? $t('title.below') : $t('title.upper')
                "
              />
            </div>
          </div>
        </template>

        <template slot-scope="scope">
          {{ getTimer(scope.row.report_time) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('title.operate')"
        width="130"
        header-align="center"
        :resizable="false"
        :align="'center'"
      >
        <template slot-scope="scope">
          <div class="table-operate">
            <div class="img-box" @click.stop="seeMsg(scope.row)">
              <img src="@/assets/images/see.png" :title="$t('title.view')" />
            </div>
            <div class="img-box" @click.stop="donwLoadMsgItemFile(scope.row)">
              <img
                src="@/assets/images/download.png"
                :title="$t('button.download')"
              />
            </div>
            <div
              v-if="false"
              class="img-box"
              @click.stop="deleteMsg(1, scope.row)"
            >
              <img
                src="@/assets/images/delete.png"
                :title="$t('button.delete')"
              />
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <msgDetails
      v-if="msgDetailsVisible"
      :visible.sync="msgDetailsVisible"
      :seeItemData="seeItemData"
      @refreshList="getMsgs"
    />
    <deleteFile
      v-if="deleteFileVisible"
      :visible.sync="deleteFileVisible"
      :deleteMsgs="deleteMsgs"
      @refreshList="getMsgs"
    />
  </div>
</template>

<script>
import msgDetails from './msgDetails.vue'
import deleteFile from './deleteFile.vue'
import {
  changeByte,
  dateFormat,
  exportsMreportMsgs,
  packageDownloadMreports,
  packageDownloadMreportItem,
} from '@/utils/fileOperation'

import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import moment from 'moment'

import { mapGetters } from 'vuex'
export default {
  name: 'uploadRecord',
  props: {},
  components: {
    msgDetails,
    deleteFile,
  },
  data() {
    return {
      exitBusinessTypeVisible: false,
      timeManagementVisible: false,
      msgDetailsVisible: false,
      deleteFileVisible: false,
      listType: 0,
      msgTableData: [],
      msgList: [],
      selectItems: [],
      locale,
      selectTime: [
        moment(Math.floor(new Date() - 1000 * 60 * 60 * 24 * 1)),
        moment(Math.floor(new Date())),
      ],
      selectData: moment(Math.floor(new Date())),
      seeItemData: {},
      deleteMsgs: [],
      searchStory: {},
      sortItem: {
        fileSize: true,
        generationTime: true,
        expirationTime: true,
        uploaded: true,
      },
      takeEffectSort: 'uploaded',
    }
  },
  computed: {
    ...mapGetters(['language']),
    searchDiabel() {
      return false
      if (!this.searchStory.begin_time) {
        return false
      } else {
        if (
          this.searchStory.begin_time ==
            Math.floor(new Date(this.selectTime[0]).getTime() / 1000) &&
          this.searchStory.end_time ==
            Math.ceil(new Date(this.selectTime[1]).getTime() / 1000)
        ) {
          return true
        } else {
          return false
        }
      }
    },
    resettingDiabel() {
      return false
    },
    msgTableSortData() {
      // 筛选派生数据
      let key = ''
      switch (this.takeEffectSort) {
        case 'fileSize':
          key = 'file_size'
          break
        case 'generationTime':
          key = 'gen_time'
          break
        case 'expirationTime':
          key = 'expire_time'
          break
        case 'uploaded':
          key = 'report_time'
          break
        default:
          break
      }
      let type = this.sortItem[this.takeEffectSort]
      if (key !== '') {
        return this.getSort(key, type, this.msgTableData)
      } else {
        return this.msgTableData
      }
    },
  },
  watch: {
    language: {
      handler: function (val) {
        if (val == 'en') {
          moment.locale('en')
        } else {
          moment.locale('zh-cn')
        }
      },
      immediate: true,
    },
  },
  created() {
    // 获取自己所有的
    // 第一次获取根组织近一天所有
    this.getMsgs()
  },
  methods: {
    handleSelectionChange(e) {
      console.log(e)
      this.selectItems = e
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'want'
      } else {
        return ''
      }
    },
    setListType(type) {
      this.listType = type
      this.getMsgs()
    },
    getMsgs() {
      let params = {}
      params.cursor = ''
      params.data = []
      params.size = 88
      // params.rorg_id = this.$store.getters['userDetails'].rorg_id
      params.type = this.listType
      // 查询时间段
      params.begin_time = Math.floor(
        new Date(this.selectData).setHours(0, 0, 0, 0) / 1000
      )
      params.end_time = Math.floor(
        new Date(this.selectData).setHours(23, 59, 59, 59) / 1000
      )
      // params.user_id = this.$store.getters['uid']
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      console.log('getSelfReportsList', params)
      this.$store
        .dispatch('user/getSelfReportsList', params)
        .then((res) => {
          // 获取成功显示
          console.log(res)
          this.msgTableData = res
          loading.close()
          this.searchStory = params
        })
        .catch((err) => {
          // 获取失败显示
          console.log(err)
          loading.close()
          this.$message({
            message: this.$t('prompt.getSelfReportsListFail'),
            type: 'error',
          })
        })
    },
    resetting() {
      this.selectTime = [
        moment(Math.floor(new Date() - 1000 * 60 * 60 * 24 * 1)),
        moment(Math.floor(new Date())),
      ]
      this.selectData = moment(Math.floor(new Date()))
      this.searchStory = {}
      // 调用方法
      this.getMsgs()
    },
    getMsgLevel(level) {
      return level == 0
        ? this.$t('title.ordinaryFiles')
        : this.$t('title.importantDocuments')
    },
    getMsgFileSize(file_size) {
      return changeByte(file_size)
    },
    getTimer(timer) {
      if (timer == 0) {
        return 0
      } else {
        return dateFormat(timer)
      }
    },
    donwLoadMsgsFile() {
      // 下载所选文件文件包
      packageDownloadMreports(this.selectItems)
        .then((res) => {})
        .catch((err) => {
          console.log(err)
          this.$message({
            message: this.$t('prompt.packageDownloadMreportsFail'),
            type: 'error',
          })
        })
    },
    exportsMsgs() {
      // 导出文件信息为表格
      exportsMreportMsgs(this.selectItems)
        .then((res) => {})
        .catch((err) => {
          console.log(err)
          this.$message({
            message: this.$t('prompt.exportsMreportMsgsFail'),
            type: 'error',
          })
        })
    },
    seeMsg(item) {
      this.msgDetailsVisible = true
      this.seeItemData = item
    },
    deleteMsg(type, item) {
      // 删除
      if (type == 1) {
        // 删除一个
        this.deleteMsgs = [item]
      } else {
        this.deleteMsgs = this.selectItems
      }
      this.deleteFileVisible = true
    },
    donwLoadMsgItemFile(item) {
      packageDownloadMreportItem(item)
        .then((res) => {})
        .catch((err) => {
          console.log(err)
          this.$message({
            message: this.$t('prompt.packageDownloadMreportItemFail'),
            type: 'error',
          })
        })
    },
    clickSort(sortType) {
      if (this.takeEffectSort == sortType) {
        // 正在生效的
        this.sortItem[sortType] = !this.sortItem[sortType]
      } else {
        this.takeEffectSort = sortType
      }
    },
    getSort(key, type, data) {
      const sortBelow = (a, b) => {
        if (a[key] * 1 > b[key] * 1) {
          return -1
        } else {
          return 1
        }
      }
      const sortUpper = (a, b) => {
        if (a[key] * 1 < b[key] * 1) {
          return -1
        } else {
          return 1
        }
      }
      if (type) {
        return data.sort(sortBelow)
      } else {
        return data.sort(sortUpper)
      }
    },
  },
}
</script>
<style lang="scss">
.ant-calendar-input {
  color: #8395a1 !important;
  background: transparent !important;
}
.el-scrollbar__wrap {
  margin-bottom: 0 !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  // width: 154px;
}
.el-select {
  margin-left: 0;
}
.operation-tips {
  width: 100%;
  height: 220px;
  padding: 0 30px;
  .masbox {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .img {
      width: 43px;
      height: 43px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .label {
      font-size: 16px;
      color: #000;
      margin-top: 30px;
    }
  }
  .btnbox {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
}
//穿透CSS
</style>
<style lang="scss" scoped>
.content::v-deep {
  .el-table th.el-table__cell.is-leaf,
  .el-table td.el-table__cell {
    color: #fff;
    background-color: #333333 !important;
    border-bottom: 1px solid #86919a;
  }
  .el-table--border .el-table__cell {
    border-right: 1px solid #86919a;
  }
  .el-table td {
    border-bottom-color: #86919a !important;
  }
  .el-table::before,
  .el-table--group::after,
  .el-table--border::after {
    background-color: #86919a;
  }
  .el-table--group,
  .el-table--border {
    border: 1px solid #86919a;
  }

  .el-input .el-input__inner {
    color: #8395a1 !important;
    background-color: transparent;
    border: 1px solid #8395a1 !important;
    margin-bottom: 0;
    border-radius: 3px;
    // margin-left: 9px;
  }
  .ant-calendar-picker {
    width: 100% !important;
  }
  .ant-input {
    background-color: transparent;
    color: #8395a1;
    border-color: #8395a1;
    input {
      font-size: 14px;
      font-family: MicrosoftYaHei;
    }
  }
  .el-table {
    background-color: transparent;
    color: #fff;
  }
  .el-table tr {
    background-color: transparent;
  }
  .el-table .el-table__row:hover {
    background-color: transparent;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: transparent;
  }
  .el-table--enable-row-hover .el-table__body .want:hover > td.el-table__cell {
    background-color: #333;
  }
  .el-table th.el-table__cell {
    // background-color: transparent;
    background: #333;
  }
  .el-table .want {
    background: #333;
  }
}
.content {
  // width: 100%;
  // height: calc(100% - 10px);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #404548;
  padding: 30px;
  // padding-bottom: 10px;
  border-radius: 5px;

  .table-operate {
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: space-around;
    margin-left: 16px;
    .img-box {
      width: 16px;
      height: 16px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .t-header {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 16px;
      line-height: 16px;
      height: 16px;
      margin-left: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .select-header {
    display: flex;
    width: 100%;
    align-items: center;
    // justify-content: space-between;
    // background-color: #fff;
    .select-item {
      // width: 220px;
      display: flex;
      height: 30px;
      align-items: center;
      margin-right: 16px;
      .label {
        // width: 106px;
        color: #fff;
        font-size: 14px;
        margin-right: 10px;
      }
      .selectbox {
        flex: 1;
        overflow: hidden;
        width: 216px;
      }
    }
    .btn {
      // margin-right: 16px;
      margin-left: 20px;

      .fileInput {
        width: 0;
        height: 0;
        opacity: 0;
      }
    }
  }
  .select-header-two {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top-multibox {
      display: flex;
      .btn-danger {
        margin-left: 20px;
      }
      .btn {
        margin-left: 20px;
      }
    }
    .switch-list {
      width: 188px;
      height: 33px;
      border: #38b0fe 1px solid;
      background-color: #d8eeff;
      border-radius: 5px;
      display: flex;
      color: #38b0fe;
      font-size: 14px;
      .item {
        flex: 1;
        text-align: center;
        line-height: 33px;
        cursor: pointer;
      }
      .item-center {
        border-left: 1px solid #38b0fe;
        border-right: 1px solid #38b0fe;
      }
      .item-selete {
        background-color: #38b0fe;
        color: #fff;
      }
    }
  }
}
</style>
