import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import layout from '@/layout'

export const asyncRoutes = []

export const constantRoutes = [
  {
    path: '/personalStorage',
    component: layout,
  },
  {
    path: '/',
    component: () => import('@/pages/login/index'),
    name: 'login',
  },
]
// children: [],

// redirect: '/',

const createRouter = () =>
  new Router({
    mode: 'history', //生产环境刷新会出现404,nginx配置即可
    // mode: 'hash',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
