import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Element from 'element-ui'
import i18n from './lang'
import Cookies from 'js-cookie'
import { ConfigProvider } from 'ant-design-vue'
// import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css
import '@/styles/zlp-animation.scss' // animation css
import '@/styles/zlp-global.scss' // global css
import '@/styles/yj-global.scss' // global css
import alibs from '@/utils/antd/index'

Vue.use(Element, { i18n: (key, value) => i18n.t(key, value) })
Vue.use(alibs)
Vue.use(ConfigProvider)
Vue.config.productionTip = false

new Vue({
  el: '#app',
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
