<template>
  <div class="zlp-dialog msg-details">
    <el-dialog
      :title="$t('title.detail')"
      :modal="true"
      :width="'800px'"
      :center="true"
      :top="'10vh'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :visible="visible"
      :destroy-on-close="true"
      :custom-class="'custom-class'"
      @close="handleCancel"
    >
      <div class="msgDetails-box">
        <div class="msg-details">
          <div class="flie-detail">
            <div class="flie">
              <div class="imgbox" v-if="fileType == 0">
                <img
                  v-if="imgData[0]"
                  :src="imgData[1]"
                  @click="seeImage"
                  alt=""
                />
              </div>
              <div class="videobox" v-if="fileType == 1">
                <video
                  id="videoview"
                  :controls="true"
                  width="370"
                  height="276"
                  :autoplay="false"
                  :src="videoBlob"
                  v-if="haveVideoBlob"
                />
              </div>
              <div class="audiobox" v-if="fileType == 2">
                <div class="audiocont">
                  <div class="control">
                    <img src="@/assets/images/ptt-stop.png" alt="" />
                  </div>
                  <div class="msed">{{ '00:04' }}</div>
                  <div class="railbox">
                    <div class="rail"></div>
                    <div class="train" :style="{ left: '75%' }"></div>
                  </div>
                  <div class="ms">{{ '00:30' }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flie-dt">
            <div class="fliename">
              {{ seeItemData.file_name }}
            </div>
            <div class="file-d">
              <div class="f-size">
                {{ `${$t('title.fileSize')}:${fileSize}` }}
              </div>
              <div class="sender">
                {{ `${$t('title.uploadPersonnel')}:${seeItemData.user_id}` }}
              </div>
            </div>
          </div>
        </div>
        <div class="address">
          <msg-map :seeItemData="seeItemData" />
        </div>
      </div>
    </el-dialog>
    <imageView
      v-if="imageViewVisible"
      :visible.sync="imageViewVisible"
      :seeImgData="seeImgData"
    />
  </div>
</template>
<script>
import msgMap from './magMap.vue'
import { getFileBlobByFid } from '@/api/user.js'
import { getBidFile } from '@/api/loadrq.js'
import { changeByte } from '@/utils/fileOperation'
import imageView from '@/components/imageView.vue'

export default {
  name: 'msgDetails',
  components: {
    msgMap,
    imageView,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    seeItemData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      imageViewVisible: false,
      selectUserExpirationTime: '',
      imgData: [false, ''],
      videoData: [false, ''],
      audioData: [false, ''],
      videoBlob: '',
      haveVideoBlob: false,
      fileSize: '',
      seeImgData: '',
    }
  },
  computed: {},
  watch: {
    seeItemData: {
      handler: function (val) {
        // 请求流
        this.fileType = val.type
        if (val.type == 0) {
          // 图片
          this.getImgFile(val.file_id)
        } else if (val.type == 1) {
          // 视频
          this.getVideoFile(val.file_id)
        } else if (val.type == 2) {
          // 音频
          this.getAudioFile(val.file_id)
        }
        this.fileSize = changeByte(val.file_size)
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleCancel() {
      this.$emit('update:visible', false)
    },
    getImgFile(fid) {
      let self = this
      console.log('获取图片资源', fid)
      self.$set(self.imgData, 0, false)
      getFileBlobByFid(fid)
        .then((res) => {
          console.log('获取图片资源完毕')
          const reader = new FileReader()
          reader.readAsDataURL(res)
          reader.onload = (ev) => {
            self.$set(self.imgData, 1, ev.target.result)
            this.$nextTick(() => {
              self.$set(self.imgData, 0, true)
            })
          }
        })
        .catch((err) => {})
    },
    getVideoFile(fid) {
      console.log('获取视频资源', fid)
      getBidFile(fid)
        .then((res) => {
          const blob = new Blob([res])
          let url = window.URL.createObjectURL(blob)
          this.videoBlob = url
          console.log('video详情播放获取视频blob成功' + url)
          this.$nextTick(() => {
            this.haveVideoBlob = true
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getAudioFile() {},
    seeImage() {
      this.seeImgData = this.imgData[1]
      this.imageViewVisible = true
    },
  },
}
</script>
<style lang="scss" scoped>
.msg-details::v-deep {
  // height: 40px;
  display: flex;
  align-items: center;
  .el-input {
    width: 100%;
    height: 28px;
    margin: 0;
  }
  .selectDJType {
    width: 100%;
  }
  .el-select {
    width: 100%;
    margin-left: 0;
  }
  .el-input .el-input__inner {
    background-color: transparent;
    border-color: #3e4143;
    color: #fff !important;
    border-radius: 5px;
  }
  .el-input .el-input__inner:focus {
    border-color: #39b0ff;
  }
  .el-input__suffix {
    height: 36px;
  }
}
// 文件详情
.msgDetails-box {
  width: 100%;
  height: 628px;
  display: flex;
  flex-direction: column;
  .msg-details,
  .address {
    width: 100%;
    flex: 1;
    display: flex;
  }
  .flie-detail,
  .setting {
    flex: 1;
    height: 100%;
  }
  .flie-dt {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    // box-sizing: content-box;
    .flie-d {
      // padding-bottom: 30px;
      .f-size {
        margin: 20px 0;
        color: #39b0ff;
      }
    }
    > div {
      padding-left: 30px;
    }
  }
  .flie-detail {
    .flie {
      width: 100%;
      height: 280px;
      // background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      .imgbox,
      .videobox,
      .audiobox {
        flex: 1;
        height: 100%;
        // background-color: #55f5ff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .imgbox {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain; //这
          cursor: pointer;
        }
      }
      .audiobox {
        .audiocont {
          width: 80%;
          height: 28px;
          background-color: #3b4349;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 28px;
          .control {
            width: 14px;
            height: 14px;
            cursor: pointer;
            line-height: 14px;
            margin-right: 5px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .railbox {
            width: 168px;
            position: relative;
            margin: 0 8px;
            display: flex;
            align-items: center;
            .rail {
              width: 100%;
              height: 1px;
              background-color: #fff;
            }
            .train {
              position: absolute;
              width: 2px;
              height: 10px;
              background-color: #fff;
            }
          }
        }
      }
    }
    .flie-d {
      height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .setting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .set-box {
      width: 290px;
      display: flex;
      height: 60px;
      align-items: center;
      .s-lable {
        width: 65px;
      }
      .s-select {
        flex: 1;
        height: 36px;
      }
    }
  }
  .address {
    position: relative;
    background-color: #fff;
  }
  .btn {
    .zlp-btn-cancel {
      margin-right: 36px;
    }
  }
}
.zlp-dialog::v-deep {
  .el-dialog--center .el-dialog__body {
    padding: 0;
  }
}
</style>
<style lang="scss">
.no-show-bottom {
  .el-dialog--center .el-dialog__body {
    padding: 0 0 !important;
  }
  .el-dialog__footer {
    padding: 0;
    padding-top: 0;
  }
}
.custom-class {
  // background: rgba(10, 10, 10, 0.6);
  // box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.5);
  // border-radius: 12px;
  background: rgba(20, 26, 29, 0.8) !important;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.5) !important;
  border-radius: 12px;
  .el-dialog__header {
    background: linear-gradient(
      90deg,
      rgba(24, 39, 51, 0) 0%,
      rgba(25, 29, 32, 0.8) 50%,
      rgba(13, 28, 39, 0) 100%
    );
    border-radius: 12px;
    padding: 0;
    .el-dialog__title {
      height: 50px;
      line-height: 50px;
      background: linear-gradient(
        90deg,
        rgba(24, 39, 51, 0) 0%,
        rgba(25, 29, 32, 0.8) 50%,
        rgba(13, 28, 39, 0) 100%
      );
      border-radius: 12px;

      font-size: 20px;
      color: #39b0ff;
    }
  }
  .el-dialog__body {
    color: #fff;
  }
}
.custom-class-padding {
  // background: rgba(10, 10, 10, 0.6);
  // box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.5);
  // border-radius: 12px;
  background: rgba(20, 26, 29, 0.8);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  .el-dialog--center .el-dialog__body {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__header {
    background: linear-gradient(
      90deg,
      rgba(24, 39, 51, 0) 0%,
      rgba(25, 29, 32, 0.8) 50%,
      rgba(13, 28, 39, 0) 100%
    );
    border-radius: 12px;
    padding: 0;
    .el-dialog__title {
      height: 50px;
      line-height: 50px;
      background: linear-gradient(
        90deg,
        rgba(24, 39, 51, 0) 0%,
        rgba(25, 29, 32, 0.8) 50%,
        rgba(13, 28, 39, 0) 100%
      );
      border-radius: 12px;

      font-size: 20px;
      color: #39b0ff;
    }
  }
  .el-dialog__body {
    color: #fff;
  }
}
</style>
