<template>
  <div class="zlp-layout">
    <div class="top">
      <div class="topbgc">
        <div class="wcomuser">
          <div class="username">{{ uid }}</div>
          <div class="wecom">{{ $t('title.WelcomeMessage') }}</div>
        </div>
        <div class="head-right">
          <div class="imgbox" @click="languageVisible = !languageVisible">
            <img :src="getLanguageIcon" alt="" />
            <div class="language" v-if="languageVisible">
              <div
                :class="['langitem', language == 'zh' ? 'select' : '']"
                @click.stop="handleSetLanguage('zh')"
              >
                {{ $t('language.simplifiedChinese') }}
              </div>
              <div
                :class="['langitem', language == 'tw' ? 'select' : '']"
                @click.stop="handleSetLanguage('tw')"
              >
                {{ $t('language.chineseTraditional') }}
              </div>
              <div
                :class="['langitem', language == 'en' ? 'select' : '']"
                @click.stop="handleSetLanguage('en')"
              >
                {{ $t('language.english') }}
              </div>
            </div>
          </div>
          <div class="imgbox loginout" @click="dialogVisible = true">
            <img src="@/assets/images/loginout.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center-top">
        <img src="@/assets/images/bg-top.png" alt="" />
        <div class="orgname">{{ userDetails.org_name }}</div>
        <div class="title">{{ $t('title.platformName') }}</div>
      </div>
    </div>
    <div class="cont">
      <uploadRecord />
    </div>
    <zlp-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :title="$t('prompt.loginOut')"
      top="35vh"
      :append-to-body="true"
      @close="dialogVisible = false"
    >
      {{ $t('prompt.isLoginOut') }}
      <template slot="bottom">
        <el-button class="zlp-btn-cancel" @click="dialogVisible = false">{{
          $t('button.cancel')
        }}</el-button>
        <el-button class="zlp-btn-ok" @click="loginout">{{
          $t('button.define')
        }}</el-button>
      </template>
    </zlp-dialog>
  </div>
</template>
<script>
import uploadRecord from './components/uploadRecord.vue'
import ZlpDialog from '@/components/ZlpDialog/index.vue'

import { mapGetters } from 'vuex'
export default {
  components: {
    uploadRecord,
    ZlpDialog,
  },
  data() {
    return {
      dialogVisible: false,
      languageVisible: false,
    }
  },
  computed: {
    ...mapGetters(['uid', 'userDetails', 'language']),
    getLanguageIcon() {
      return require(`@/assets/images/${this.language}.png`)
    },
  },
  watch: {},
  mounted() {
    console.log(2222)
  },
  methods: {
    loginout() {
      // 退出登录
      // 调用退出，调用清除本地TOKEN
      this.dialogVisible = false
      this.$store.dispatch('user/loginOut')
      this.$router.push(`/`)
    },
    handleSetLanguage(lang) {
      console.log('handleSetLanguage', lang)
      this.$i18n.locale = lang
      this.$store.dispatch('user/setLanguage', lang)
      this.languageVisible = false
      this.$message({
        message: 'Switch Language Success',
        type: 'success',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.zlp-layout {
  position: relative;
  z-index: 1;
  // height: 1080px;
  height: 100vh;
  // width: 100%;
  // width: 1920px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .cont {
    flex: 1;
    // padding-bottom: 10px;
    margin: 0 14px;
    margin-top: -198px;
    overflow: hidden;
    margin-bottom: 5px;
  }
  .top {
    width: 100%;
    /*  position: absolute;
    left: 0;
    top: 0; */
    height: 330px;
    background-image: linear-gradient(black, #fff);
    .topbgc {
      height: 93px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #333333;
      color: #e0e0e0;
      padding: 0 50px;
      .wcomuser {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        .username {
          margin-right: 20px;
        }
      }
      .head-right {
        display: flex;
        .imgbox {
          width: 24px;
          height: 24px;
          position: relative;
          cursor: pointer;
          .language {
            position: absolute;
            width: 88px;
            height: 120px;
            left: -36px;
            bottom: -142px;
            background-color: #1a2023;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            overflow: hidden;
            .langitem {
              width: 100%;
              flex: 1;
              line-height: 40px;
            }
            .select {
              background-color: #39b0ff;
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .loginout {
          margin-left: 25px;
        }
      }
    }
    .center-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      color: #4dabe6;
      .orgname {
        position: absolute;
        top: 26px;
        font-size: 26px;
        font-weight: 600;
      }
      .title {
        position: absolute;
        top: 66px;
        font-size: 16px;
      }
    }
  }
}
</style>
