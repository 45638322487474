<template>
  <div class="zlp-delete-org">
    <zlp-dialog
      :visible.sync="visible"
      :title="$t('title.recordDeletion')"
      width="30%"
      top="35vh"
      :append-to-body="true"
      @close="handleClose()"
    >
      {{ $t('title.isRecordDeletion') }}
      <template slot="bottom">
        <el-button class="zlp-btn-cancel" @click="handleCancel()">{{
          $t('button.cancel')
        }}</el-button>
        <el-button class="zlp-btn-delete" @click="handleDelete()">{{
          $t('button.define')
        }}</el-button>
      </template>
    </zlp-dialog>
  </div>
</template>
<script>
import ZlpDialog from '@/components/ZlpDialog/index.vue'

export default {
  components: {
    ZlpDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    deleteMsgs: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    handleDelete() {
      // 调用LOading
      console.log(this.deleteMsgs)
      let report_ids = []
      for (let i = 0; i < this.deleteMsgs.length; i++) {
        report_ids.push(this.deleteMsgs[i].report_id)
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.$store
        .dispatch('user/deleteReports', report_ids)
        .then((res) => {
          this.$emit('refreshList')
          loading.close()
          this.$emit('update:visible', false)
        })
        .catch((err) => {
          console.log(err)
          loading.close()
          this.$message({
            message: this.$t('prompt.recordDeletionFail'),
            type: 'error',
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.zlp-form {
  height: 100px;
}
</style>
