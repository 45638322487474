<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import enGB from 'ant-design-vue/es/locale/en_GB'
import zhTW from 'ant-design-vue/es/locale/zh_TW'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    HelloWorld,
  },
  data() {
    return {
      locale: zhCN,
    }
  },
  mounted() {
    console.log('00000000')
    /* this.$router.push({
      path: '/',
      query: {},
    }) */
  },
  computed: {
    ...mapGetters(['language']),
  },
  watch: {
    language: {
      handler: function (val) {
        switch (val) {
          case 'zh':
            this.locale = zhCN
            break
          case 'tw':
            this.locale = zhTW

            break
          case 'en':
            this.locale = enGB
            break
          default:
            this.locale = zhCN
            break
        }
        // 动态设置title
        document.title = this.$t('title.platformName')
      },
      immediate: true,
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
body {
  margin: 0;
}
</style>
