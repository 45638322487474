import request from '@/utils/request'
import store from 'vuex'
import { jsonp } from 'vue-jsonp'

const getByBaidu = function (url) {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await jsonp(url)
      resolve(res)
    } catch (error) {
      console.log(error, url)
      reject(error)
    }
  })
}
export function getBD09Point(type, point, akey) {
  return new Promise((resolve, reject) => {
    let url = `https://api.map.baidu.com/geoconv/v1/?coords=${point}&from=${type}&to=5&ak=${akey}`
    getByBaidu(url)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}
export function getPointAddr(point, akey) {
  // console.log(point);

  return new Promise((resolve, reject) => {
    let url = `https://api.map.baidu.com/reverse_geocoding/v3/?ak=${akey}&location=${point}&output=json`
    getByBaidu(url)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}
