import request from '@/utils/request'
export function newTokenWithPassword(username, password, pic_code, ve_code) {
  console.log(username, password, pic_code, ve_code)
  return request({
    url: '/auth/newTokenWithPassword',
    method: 'post',
    data: {
      username: username,
      password: password,
      pic_code,
      ve_code,
      token_type: 0,
    },
  })
}
export function generateVeCodeImage(username) {
  return request({
    url: '/auth/generateVeCodeImage',
    method: 'post',
    data: {
      username: username,
    },
  })
}
export function getSelfOrgName() {
  return request({
    url: '/org/getSelfOrgName',
    method: 'post',
    data: {},
  })
}
export function revokeToken() {
  return request({
    url: '/auth/revokeToken',
    method: 'post',
    data: {},
  })
}
//获取上报记录列表
export function getReportsList(params) {
  return request({
    url: '/mreport/getReportsList',
    method: 'post',
    data: params,
  })
}

//获取个人上报记录列表
export function getSelfReportsList(params) {
  return request({
    url: '/mreport/getSelfReportsList',
    method: 'post',
    data: params,
  })
}
//删除媒体上报记录
export function deleteReports(rorg_id, report_ids) {
  return request({
    url: '/mreport/deleteReports',
    method: 'post',
    data: {
      rorg_id,
      report_ids,
    },
  })
}
export function getFileBlobByFid(fid) {
  return request({
    url: '/file/download/' + fid,
    responseType: 'blob',
    method: 'get',
    data: {},
  })
}
