<template>
  <div class="zlp-dialog" :class="getBottomStyle">
    <el-dialog
      :title="title"
      :visible="visible"
      :width="width"
      :top="top"
      :modal-append-to-body="modalAppendToBody"
      :append-to-body="appendToBody"
      :center="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="destroyOnClose"
      :modal="modal"
      :fullscreen="false"
      :show-close="showClose"
      :custom-class="getPadding"
      @close="handleClose"
    >
      <!-- :before-close="beforeClose" -->
      <slot />
      <span v-if="isFooter" slot="footer" class="dialog-footer">
        <slot name="bottom" />
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'ZlpDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '30%',
    },
    beforeClose: {
      type: Function,
      default: null,
    },
    top: {
      type: String,
      default: '12%',
    },
    modal: {
      type: Boolean,
      default: true,
    },
    modalAppendToBody: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    isFooter: {
      type: Boolean,
      default: true,
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
    isPadding: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    getBottomStyle() {
      if (!this.isFooter) {
        return 'no-show-bottom'
      } else {
        return ''
      }
    },
    getPadding() {
      if (this.isPadding) {
        return 'custom-class'
      } else {
        return 'custom-class-padding'
      }
    },
    getStyle() {
      let style = ''
      let top = '20px'
      let left = '20px'
      if (!this.position || this.position === 'center') {
        left = '35%;'
        top = '60px;'
        style = `left:${left};top:${top};`
      }
      style = `${style}width:${this.width};`
      return style
    },
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('close', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.zlp-dialog {
  // position: absolute;
  position: relative;
  background: rgba(20, 26, 29, 0.8);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  color: #fff;
  .title {
    width: 100%;
    height: 50px;
    background: linear-gradient(
      90deg,
      rgba(24, 39, 51, 0) 0%,
      rgba(25, 29, 32, 0.8) 50%,
      rgba(13, 28, 39, 0) 100%
    );
    font-size: 20px;
    color: #39b0ff;
    line-height: 50px;
    text-align: center;
  }
  .content {
    padding: 10px 20px;
    max-height: 550px;
    max-width: 550px;
    min-height: 50px;
    overflow-y: auto;
    // line-height: normal;
    word-wrap: break-word;
  }
  .operator {
    text-align: center;
    width: 100%;
    line-height: normal;
    padding: 20px;
  }
}
</style>
<style lang="scss">
.no-show-bottom {
  .el-dialog--center .el-dialog__body {
    padding: 0 0 !important;
  }
  .el-dialog__footer {
    padding: 0;
    padding-top: 0;
  }
}
.custom-class {
  // background: rgba(10, 10, 10, 0.6);
  // box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.5);
  // border-radius: 12px;
  background: rgba(20, 26, 29, 0.8);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  .el-dialog__header {
    background: linear-gradient(
      90deg,
      rgba(24, 39, 51, 0) 0%,
      rgba(25, 29, 32, 0.8) 50%,
      rgba(13, 28, 39, 0) 100%
    );
    border-radius: 12px;
    padding: 0;
    .el-dialog__title {
      height: 50px;
      line-height: 50px;
      background: linear-gradient(
        90deg,
        rgba(24, 39, 51, 0) 0%,
        rgba(25, 29, 32, 0.8) 50%,
        rgba(13, 28, 39, 0) 100%
      );
      border-radius: 12px;

      font-size: 20px;
      color: #39b0ff;
    }
  }
  .el-dialog__body {
    color: #fff;
  }
}
.custom-class-padding {
  // background: rgba(10, 10, 10, 0.6);
  // box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.5);
  // border-radius: 12px;
  background: rgba(20, 26, 29, 0.8);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  .el-dialog--center .el-dialog__body {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__header {
    background: linear-gradient(
      90deg,
      rgba(24, 39, 51, 0) 0%,
      rgba(25, 29, 32, 0.8) 50%,
      rgba(13, 28, 39, 0) 100%
    );
    border-radius: 12px;
    padding: 0;
    .el-dialog__title {
      height: 50px;
      line-height: 50px;
      background: linear-gradient(
        90deg,
        rgba(24, 39, 51, 0) 0%,
        rgba(25, 29, 32, 0.8) 50%,
        rgba(13, 28, 39, 0) 100%
      );
      border-radius: 12px;

      font-size: 20px;
      color: #39b0ff;
    }
  }
  .el-dialog__body {
    color: #fff;
  }
}
</style>
